import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ProjectSlide from "./ProjectSlide"

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  cursor: url("/cursor-plus.png") 64 64, auto;
  & .slick-dots {
    position: absolute;
    bottom: 40px;
    padding: 0;
    margin: 0;
    width: auto;
    right: 3%;
    @media (max-width: 767px) {
      display: none !important;
    }
    & > li {
      width: auto;
      height: auto;
      & > button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        transform: scale(0.8);
      }
      &.slick-active > button {
        background-color: white;
      }
      & > button::before {
        content: "";
      }
    }
  }
`

const Numbers = styled.div`
  position: absolute;
  top: 50%;
  right: 3%;
  @media (max-width: 767px) {
    right: 5%;
  }
  transform: rotate(-90deg) translate(50%, 0px);
  font-family: "Engravers Gothic";
  color: white;
  font-size: 12px;
  transform-origin: bottom right;
  letter-spacing: 1px;
`
const Separador = styled.span`
  margin: 0 12px;
`

const ProjectCarousel = ({ projects }) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    afterChange: index => setCurrentIndex(index + 1),
  }
  return (
    <Wrapper>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <ProjectSlide project={project} key={project.fields.slug} />
        ))}
      </Slider>
      <Numbers>
        {(currentIndex < 10 && "0") + currentIndex}
        <Separador>—</Separador>
        {(projects.length < 10 && "0") + projects.length}
      </Numbers>
    </Wrapper>
  )
}

ProjectCarousel.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      categories: PropTypes.string,
    })
  ),
}

ProjectCarousel.defaultProps = {
  projects: [],
}

export default ProjectCarousel
