import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import styled from "styled-components"
import { space } from "styled-system"

import { ProjectCard } from "."
import { ButtonWithPlus } from "../Button"

const Wrapper = styled.div`
  ${space};
`

const ProjectsList = props => {
  const { projects } = props
  const itemsPerPage = 8
  const initialProjects = projects.slice(0, itemsPerPage)

  const [totalItems, setTotalItems] = useState(itemsPerPage)
  const [showLoadMore, setShowLoadMore] = useState(true)
  const [loadedProject, setLoadedProjects] = useState(initialProjects)

  useEffect(() => {
    setLoadedProjects(projects.slice(0, totalItems))
    setTimeout(function () {
      if (projects.length <= totalItems) {
        setShowLoadMore(false)
      } else {
        setShowLoadMore(true)
      }
    }, 100)
  }, [totalItems, projects])

  const handleLoadMoreClick = () => {
    setTotalItems(totalItems + itemsPerPage)
  }

  return (
    <Wrapper {...props}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 695: 2 }}>
        <Masonry gutter="45px">
          {loadedProject.map(project => {
            const title = project.frontmatter.title || project.fields.slug

            return (
              <ProjectCard
                slug={project.fields.slug}
                title={title}
                image={get(project, "frontmatter.card.publicURL", null)}
                extra={project.frontmatter.extra}
                tags={project.frontmatter.tags}
                key={project.fields.slug}
              />
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
      {showLoadMore && (
        <ButtonWithPlus onClick={handleLoadMoreClick} label="load more" />
      )}
    </Wrapper>
  )
}

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      extra: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    })
  ),
}

ProjectsList.defaultProps = {
  projects: [],
}

export default ProjectsList
