import React, { useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { background, layout } from "styled-system"
import { isMobile } from "react-device-detect"
import innerHeight from "ios-inner-height"

import { H2, P } from "../Text"

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  ${layout};
  ${background};
`

const SlideLinkArea = styled(Link)`
  position: absolute;
  bottom: 36px;
  text-decoration: none;
  color: ${props => props.color};
  transition: color 0.3s ease-in-out;
  cursor: url("/cursor-plus.png") 64 64, auto;
  left: 2.5%;
  @media (max-width: 767px) {
    left: 5%;
  }
  &:hover {
    opacity: 0.65;
  }
`

function useWindowSize() {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {
      const bannerHeight = isMobile ? innerHeight() - 100 : innerHeight()
      setSize([window.innerWidth, bannerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

const ProjectSlide = ({ project }) => {
  const [width, height] = useWindowSize()
  const [mousePos, setMousePos] = useState()

  const handleClickDown = e => {
    setMousePos(`${e.pageX} ${e.pageY}`)
  }
  const handleClickUp = (e, link) => {
    if (`${e.pageX} ${e.pageY}` === mousePos) {
      navigate(link)
    }
  }

  return (
    <Wrapper
      height={height}
      width={width}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={[
        `url('${project.frontmatter.featuredCoverMobile.publicURL}')`,
        `url('${project.frontmatter.featuredCover.publicURL}')`,
      ]}
      onMouseDown={handleClickDown}
      onMouseUp={e => handleClickUp(e, project.fields.slug)}
    >
      <SlideLinkArea
        to={project.fields.slug}
        color={project.frontmatter.contrastColor}
      >
        <H2 mb={-1}>{project.frontmatter.title}</H2>
        <P>{project.frontmatter.extra}</P>
      </SlideLinkArea>
    </Wrapper>
  )
}

ProjectSlide.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    extra: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default ProjectSlide
