import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import styled from "styled-components"
import { Container, Row, Col, Visible, Hidden } from "react-awesome-styled-grid"
import { background } from "styled-system"

import { H2, P } from ".."
import { space } from "styled-system"

const StyledHeader = styled.header`
  width: 100%;
`

const Cover = styled.div`
  width: 100%;
  height: 80vh;
  min-height: 400px;
  background-size: cover;
  background-position: center;
  ${background};
`

const Intro = styled(Container)`
  text-align: center;
  margin-bottom: ${props => props.theme.space[2]}px;
  ${space}
`

const Separador = styled.span`
  margin: 0 14px;
`

const ProjectHeader = ({
  cover,
  coverMobile,
  title,
  description,
  extra,
  client,
}) => {
  const formatedDescription = description.replace(`[/n]`, '\n')

  return (
    <StyledHeader>
      <Cover
        backgroundImage={[
          `url('${get(coverMobile, "publicURL", null)}')`,
          `url('${get(cover, "publicURL", null)}')`,
        ]}
      />
      <Intro mt={[3, 3, 4]} mb={[4, 4, 5]}>
        <Row>
          <Col xs={12} sm={10} md={8} offset={{ xm: 0, sm: 1, md: 2 }}>
            <Visible xs>
              <P as="h1">
                <em>Project</em>. {title}
              </P>
              <P as="h1">
                <em>Client</em>. {client}
              </P>
            </Visible>
            <Hidden xs>
              <P as="h1">
                <em>Project</em>. {title} <Separador>/</Separador>{" "}
                <em>Client</em>. {client}
              </P>
            </Hidden>
            <H2 my={[3, 3, 5]}>{formatedDescription}</H2>
            <P>{extra}</P>
          </Col>
        </Row>
      </Intro>
    </StyledHeader>
  )
}

ProjectHeader.propTypes = {
  cover: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  extra: PropTypes.string,
  client: PropTypes.string,
}

ProjectHeader.defaultProps = {
  cover: "",
  title: "",
  description: "",
  extra: "",
  client: "",
}

export default ProjectHeader
