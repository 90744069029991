import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

import { Small, H2 } from "../Text"

const EachWrapper = styled.div`
  display: inline-block;
  padding: 50px 25px;
  position: relative;
  & > img {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    width: 167px;
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translate(0, -50%);
    z-index: -1;
  }
  &:hover > img {
    opacity: 1;
  }
`

const OtherProject = ({ project }) => {
  let projID =
    project.frontmatter.id < 10
      ? "0" + project.frontmatter.id
      : project.frontmatter.id
  return (
    <EachWrapper>
      <img
        src={project.frontmatter.card.publicURL}
        alt={project.frontmatter.title}
      />
      <Small mb={1}>{projID}</Small>
      <H2 as="span">
        <Link to={project.fields.slug}>{project.frontmatter.title}</Link>
      </H2>
    </EachWrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const ListWrapper = styled.div`
  overflow-x: scroll;
  width: 100%;
  list-style: none;
  white-space: nowrap;
  padding: 0 5%;
`
const FadeOut = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.9), rgba(255,255,255,1));
  z-index: 99;
  width: 5%;
  height: 100%;
`
const FadeIn = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,0.9), rgba(255,255,255,1));
  z-index: 99;
  width: 5%;
  height: 100%;
`

const OtherProjects = ({ projects }) => {
  return (
    <Wrapper>
      <ListWrapper>
        {projects.map(project => (
          <OtherProject key={project.fields.slug} project={project} />
        ))}
      </ListWrapper>
      <FadeIn />
      <FadeOut />
    </Wrapper>
  )
}

OtherProjects.propTypes = {
  projects: PropTypes.array,
}

OtherProjects.defaultProps = {
  projects: [],
}

export default OtherProjects
