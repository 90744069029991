import React from "react"
import styled from "styled-components"

import { P } from ".."

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 3%;
  @media (max-width: 767px) {
    padding: 40px 5%;
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <P>Share your ideas with us.</P>
      <P>all rights reserved @ 327</P>
    </Wrapper>
  )
}

export default Footer
