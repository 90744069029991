import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { space } from "styled-system"
import { Container, Row, Col } from "react-awesome-styled-grid"

import { H1, H2 } from "."

const Wrapper = styled(Container)`
  text-align: center;
  margin-bottom: ${props => props.theme.space[2]}px;
  ${space}
`

const LeadText = ({ title, text, link }) => {
  return (
    <Wrapper py={[4, 4, 5]}>
      <Row>
        <Col
          xs={12}
          sm={10}
          md={8}
          xl={6}
          offset={{ xm: 0, sm: 1, md: 2, xl: 3 }}
        >
          <H1 mb={2}>{title}</H1>
          <H2>{link ? <a href={link}>{text}</a> : text}</H2>
        </Col>
      </Row>
    </Wrapper>
  )
}

LeadText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
}

LeadText.defaultProps = {
  title: "",
  text: "",
  link: "",
}

export default LeadText
