import React from "react"
import styled from "styled-components"
import { space } from "styled-system"

const HeaderSpacer = styled.div`
  height: 100px;
`

const StyledArticleSpacer = styled.div`
  height: 0;
  ${space};
  & + & {
    padding-bottom: 70px;
  }
`

const ArticleSpacer = () => (
  <StyledArticleSpacer pb={12} />
)

export { HeaderSpacer, ArticleSpacer }
