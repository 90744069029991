import React from "react"
import styled from "styled-components"
import { space, typography } from "styled-system"
import { Col } from "react-awesome-styled-grid"

const H1 = styled.h1`
  font-family: "Euclid Flex";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 1.4px;
  ${space}
  ${typography};
`

const H2 = styled.h2`
  font-family: "Saol Display";
  font-weight: normal;
  font-size: 50px;
  line-height: 1.3;
  letter-spacing: -1.5px;
  @media (max-width: 767px) {
    font-size: 36px;
    letter-spacing: -1px;
  }
  ${space};
  ${typography};
  & > a {
    text-decoration: none;
    color: inherit;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
`

const H3 = styled.h3`
  font-family: "Saol Display";
  font-weight: normal;
  font-size: 30px;
  line-height: 1.3;
  @media (max-width: 767px) {
    font-size: 36px;
  }
  ${space};
  ${typography};
  & > a {
    text-decoration: none;
    color: inherit;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
`

const H4 = styled.h4`
  font-family: "Saol Display";
  font-weight: normal;
  font-size: 24px;
  line-height: 1.3;
  @media (max-width: 767px) {
    font-size: 36px;
  }
  ${space};
  ${typography};
  & > a {
    text-decoration: none;
    color: inherit;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
`

const H5 = styled.h5`
  font-family: "Euclid Flex";
  font-weight: normal;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 1.5px;
  ${space};
  ${typography};
  & > a {
    text-decoration: underline;
    color: inherit;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
`

const H6 = styled.h6`
  font-family: "Euclid Flex";
  font-weight: 300;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 1.5px;
  ${space};
  ${typography};
  & > a {
    text-decoration: underline;
    color: inherit;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
`

const H5space = ({ children }) => (
  <Col xs={12} sm={10} md={6} offset={{ xs: 0, sm: 1, md: 3 }}>
    <H5 textAlign="center" my="12px">
      {children}
    </H5>
  </Col>
)

const H6space = ({ children }) => (
  <Col xs={12} sm={10} md={6} offset={{ xs: 0, sm: 1, md: 3 }}>
    <H6 textAlign="center" my="12px">
      {children}
    </H6>
  </Col>
)

export { H1, H2, H3, H4, H5, H5space, H6, H6space }

//
// font-family: 'Euclid Flex';
// font-weight: bold;
//
// font-family: 'Euclid Flex';
// font-weight: 500;
//
// font-family: 'Euclid Flex';
// font-weight: normal;
//
// font-family: 'Euclid Flex';
// font-weight: 300;
//
// font-family: 'EngraversGothicW01';
// font-weight: bold;
//
// font-family: 'Engravers Gothic';
// font-weight: normal;
//
// font-family: 'Saol Display';
// font-weight: normal;
