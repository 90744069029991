import React from "react"
import { ThemeProvider } from 'styled-components'

import { Header, Footer } from ".."
import grid from './grid'
import theme from './theme'

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider theme={{ ...theme, awesomegrid: grid }}>
      <Header location={location} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
