export default {
  mediaQuery: 'only screen',
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  gutterWidth: {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
  },
  paddingWidth: {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 0,
    xl: 0,
  },
  container: {
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: 75, // 1200px
    xl: 90, // 1440px
  },
  breakpoints: {
    xs: 1,
    sm: 48,
    md: 64,
    lg: 90,
    xl: 120,
  },
}
