import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Hidden } from "react-awesome-styled-grid"
import { HamburgerSqueeze } from "react-animated-burgers"

import { Logo, H1, SocialLinks } from ".."

const Wrapper = styled.header`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 3%;
  @media (max-width: 767px) {
    padding: 40px 5%;
  }
`

const HamburgerWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 21px;
  z-index: 9;
  & button {
    padding: 0;
    position: fixed;
    right: 3%;
    @media (max-width: 767px) {
      right: 5%;
    }
    & span,
    & span::after,
    & span::before {
      height: 2px;
    }
    & span::before {
      width: 14px;
    }
  }
`

const Drawer = styled.div`
  position: fixed;
  z-index: 8;
  top: 0;
  left: 100%;
  width: 50%;
  height: 100%;
  background-color: #efeae1;
  transition: transform 0.35s ease-in-out;
  transform: ${props => (props.isOpen ? "translate(-100%)" : "translate(0)")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  text-align: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const MenuLink = styled(Link)`
  font-family: "Saol Display";
  font-weight: normal;
  font-size: 60px;
  text-decoration: none;
  display: block;
  padding: 10px;
  margin: 10px;
  color: #232222;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.15);
  }
`

const Header = ({ location }) => {
  const [isMenuOpen, setMenuState] = useState(false)
  const handleHamburgerClick = () => {
    setMenuState(!isMenuOpen)
  }
  const colorVariation =
    location.pathname.includes("work") ||
    location.pathname.includes("about") ||
    location.pathname.includes("contacts")
      ? "#232222"
      : "white"
  return (
    <>
      <Wrapper>
        <div>
          <Link
            to="/"
            style={{ zIndex: "7", position: "relative", top: "-2px" }}
          >
            <Logo variation={colorVariation} />
          </Link>
        </div>
        <HamburgerWrapper>
          <HamburgerSqueeze
            buttonWidth={32}
            isActive={isMenuOpen}
            toggleButton={handleHamburgerClick}
            barColor={colorVariation}
          />
        </HamburgerWrapper>
      </Wrapper>
      <Drawer isOpen={isMenuOpen}>
        <div>
          <Hidden xs>
            <H1 as="p">hey</H1>
          </Hidden>
        </div>
        <div>
          <MenuLink to="/about">studio</MenuLink>
          <MenuLink to="/work">work</MenuLink>
          <MenuLink to="/contacts">contacts</MenuLink>
        </div>
        <SocialLinks />
      </Drawer>
    </>
  )
}

export default Header
