import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { space } from "styled-system"

const Wrapper = styled.div`
  text-align: center;
  ${space};
`

const StyledButton = styled.button`
  background: none;
  border: none;
  transition: opacity 0.2s ease-in-out;
  color: #232222;
  &>p{
    margin-top: 24px;
    font-family: "Engravers Gothic";
    font-size: 15px;
    letter-spacing: 1.8px;
    color: #232222;
    text-decoration: none;
  }
  &:hover {
    opacity: 0.6;
  }
`

const ButtonWithPlus = ({ label, onClick }) => {
  return (
    <Wrapper mt={[4,4,5]}>
      <StyledButton onClick={onClick}>
        <img src="/more-work.svg" alt="plus icon" />
        <p>{label}</p>
      </StyledButton>
    </Wrapper>
  )
}

ButtonWithPlus.propTypes = {
  label: PropTypes.string,
}

ButtonWithPlus.defaultProps = {
  label: "Click me",
}

export default ButtonWithPlus
