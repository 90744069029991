import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { Visible, Hidden } from "react-awesome-styled-grid"
import { space } from "styled-system"

import { H5 } from ".."

const StyledCard = styled.div``
const ImageWrapper = styled(Link)`
  display: block;
  margin-bottom: 20px;
  & img {
    max-width: 100%;
    display: block;
  }
`

const ImageOrig = styled.img`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.015);
  }
`
const ImageSquare = styled.div`
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`

const CardTitle = styled(Link)`
  font-family: "Euclid Flex";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: #232222;
  text-decoration: none;
  ${space};
  &:hover {
    color: grey;
  }
`

const ProjectCard = ({ title, extra, slug, image }) => {
  return (
    <StyledCard>
      <ImageWrapper to={slug}>
        <Hidden xs>
          <ImageOrig src={image} alt="project card" />
        </Hidden>
        <Visible xs>
          <ImageSquare bg={image} />
        </Visible>
      </ImageWrapper>
      <CardTitle to={slug}>{title}</CardTitle>
      <H5>{extra}</H5>
    </StyledCard>
  )
}

ProjectCard.propTypes = {
  title: PropTypes.string,
  extra: PropTypes.string,
  slug: PropTypes.string,
}

ProjectCard.defaultProps = {
  title: "",
  extra: "",
  slug: "",
}

export default ProjectCard
