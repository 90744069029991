import React from "react"
import styled from "styled-components"

import logo from "./logo.svg"
import logoWhite from "./logo_white.svg"

const StyledImg = styled.img`
  width: 175px;
`

const Logo = ({ variation }) => (
  <StyledImg
    src={variation === "white" ? logoWhite : logo}
    alt="327 creative studio"
  />
)

export default Logo
