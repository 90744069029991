import styled from "styled-components"
import { space } from "styled-system"

export const P = styled.p`
  font-family: "Euclid Flex";
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1.4px;
  line-height: 1.8;
  ${space}
  &>a {
    color: inherit;
  }
  & > em {
    text-decoration: underline;
  }
`

export const Small = styled.p`
  font-family: "Euclid Flex";
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1.4px;
  ${space}
  &>a {
    color: inherit;
  }
`
