const breakpoints = ['768px', '1024px', '1440px', '1920px']

/* eslint-disable prefer-destructuring */
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
/* eslint-enable prefer-destructuring */

export default {
  name: 'Main',
  breakpoints,
  space: [0, 8, 24, 40, 60, 100, 180],
  fontSizes: [12, 14, 16, 18, 20, 26, 30, 40, 50],
  fonts: {
    heading: 'Poppins',
    body: 'Open Sans, sans-serif',
  },
  colors: {
    primary: {
      dark: '#40a771',
      main: '#4CE897',
      light: '#DEFFEE',
    },
    secondary: {
      dark: '#172d3d',
      main: '#5f6e79',
      light: '#8FA6BC',
    },
    grey: {
      dark: '#555',
      main: '#eaeaea',
      light: '#fff',
    },
    error: {
      main: '#ff5858',
      light: '#ffe3e3',
    },
    info: {
      main: '#588dff',
      light: '#d8e4ff',
    },
  },
}
