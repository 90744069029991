import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.p`
  width: 100%;
  margin-bottom: 24px;
  font-family: "Euclid Flex";
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1.4px;
  text-align: right;
  @media (max-width: 767px) {
    text-align: center;
  }
  & > button {
    background: none;
    border: none;
    text-decoration: underline;
    padding: 2px 10px;
    font: inherit;
    letter-spacing: 1.4px;
    &:hover {
      color: grey;
    }
    &:disabled {
      color: grey;
      text-decoration: none;
    }
  }
`

const ProjectsTags = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

ProjectsTags.propTypes = {
  varA: PropTypes.string,
}

ProjectsTags.defaultProps = {
  varA: "",
}

export default ProjectsTags
