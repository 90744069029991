import React from "react"
import styled from "styled-components"

import { P } from "./Text"

const SocialLink = styled.a`
  padding: 0 16px;
`

const SocialLinkSeparator = styled.span`
  padding: 0 16px;
  &::after {
    content: "/";
  }
`

const SocialLinks = () => (
  <P>
    <SocialLink target="_blank" href="https://www.behance.net/327creativestudio">
      behance
    </SocialLink>
    <SocialLinkSeparator />
    <SocialLink target="_blank" href="https://www.instagram.com/327creativestudio/">
      instagram
    </SocialLink>
    <SocialLinkSeparator />
    <SocialLink target="_blank" href="https://www.linkedin.com/company/327-creative-studio/">
      linkedin
    </SocialLink>
  </P>
)

export default SocialLinks
